article,aside,details,figcaption,figure,footer,header,hgroup,nav,section{display:block;}audio[controls],canvas,video{display:inline;zoom:1px;}html{overflow-y:scroll;-webkit-tap-highlight-color:rgba(0,0,0,0);-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;}body,button,input,select,textarea{font-family:sans-serif;}img{border:0;-ms-interpolation-mode:bicubic;}a{color:#00e;}a:visited{color:#551a8b;}a:focus{outline:thin dotted;}a:hover,a:active{outline:0;}abbr[title]{border-bottom:1px dotted;}b,strong{font-weight:700;}blockquote{margin:1em 40px;}dfn{font-style:italic;}mark{background:#ff0;color:#000;}pre,code,kbd,samp{font-family:monospace, monospace;_font-family:'courier new', monospace;font-size:1em;}pre{white-space:pre-wrap;word-wrap:break-word;}q{quotes:none;}q:before,q:after{content:none;}small{font-size:75%;}sub,sup{font-size:75%;line-height:0;position:relative;vertical-align:baseline;}sup{top:-0.5em;}sub{bottom:-0.25em;}ul,ol{margin:0;padding:0;}li{list-style:none; line-height: 1;}dd{margin:0 0 0 40px;}nav ul,nav ol{list-style:none;}fieldset{margin:0 2px;padding:0.35em 0.625em 0.75em;}legend{margin-left:-7px;}button,input,select,textarea{font-size:100%;vertical-align:middle;margin:0;}button,input{line-height:normal;overflow:visible;}button,input[type="button"],input[type="reset"],input[type="submit"]{cursor:pointer;-webkit-appearance:button;}input[type="checkbox"],input[type="radio"]{box-sizing:border-box;}input[type="search"]{-webkit-appearance:textfield;-moz-box-sizing:content-box;-webkit-box-sizing:content-box;box-sizing:content-box;}input[type="search"]::-webkit-search-decoration{-webkit-appearance:none;}button::-moz-focus-inner,input::-moz-focus-inner{border:0;padding:0;}textarea{overflow:auto;vertical-align:top;}table{border-collapse:collapse;border-spacing:0;}th,td{text-align:left;vertical-align:middle;padding:0;}body,ul,li,figure,form,h1,h2,h3,h4,h5,h6,p{margin:0; padding: 0;}

* { -moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box; padding: 0; margin: 0; }

/*......................Media Surgery ............................
COLOURS:
Med blue : 	 	  #009fe3
*/


$base-font-size: 112.5; // Gets used as %, converts to 18px
$base-font-size: 125; // Gets used as %, converts to 20px
//$base-font-size: 137.5; // Gets used as %, converts to 22px
$line-height: 1.55;
$base: ($base-font-size / 100); // We'll need this for some calculations
 
// Modular scale
$ratio: 1.5; // Perfect Fifth

// pow and ms functions 
@function pow($number, $exponent) {
  $value: 1;

  @if $exponent > 0 {
    @for $i from 1 through $exponent {
      $value: $value * $number; //Multiply by $number if exponent less than zero
    }
  } @else if $exponent < 0 {
    @for $i from 1 through -$exponent {
      $value: $value / $number; //Divide by $number if exponent less than zero
    }
  }

  @return $value;
}

@function ms($value, $ms-ratio: $ratio, $ms-base: $base){
  $size: pow($ms-ratio, $value) * $ms-base;
  @return #{ $size + "rem" };
}

// Vertical rhythm mixins
@mixin line-height($number) {
  line-height: #{ $number * $line-height + 'rem'};
}

@mixin margin-top($number) {
  margin-top: #{ $number * $line-height + 'rem'};
}

@mixin margin-bottom($number) {
  margin-bottom: #{ $number * $line-height + 'rem'};
}


@function howmanyrems($number){
  @return #{$number * $line-height + 'rem'};
}



html {
  font-size: #{$base-font-size + '%'}; // 112.5% = 18 pixels
}


small, figcaption {
  font-size: ms(-1);
}


// Apply the baseline grid as background
.grid {
  background-image: linear-gradient(to bottom, hsla(200, 100%, 50%, 0.3) 1px, transparent 1px);
  background-repeat: repeat;
  background-position: left top;
  background-size: 100% #{($base-font-size / 100 * 16) * $line-height + "px"};
}

.double-grid {
  @extend .grid;
  background-image: linear-gradient(to bottom, hsla(200, 100%, 50%, 0.3) 1px, transparent 1px), linear-gradient(to bottom, hsla(200, 100%, 50%, 0.3) 1px, transparent 1px, transparent #{ 0.5 * ($base-font-size / 100 * 16) * $line-height + "px"}, hsla(200, 100%, 50%, 0.2) #{ 0.5 * ($base-font-size / 100 * 16) * $line-height + "px"}, transparent #{ 0.5 * ($base-font-size / 100 * 16) * $line-height + 1 + "px"}, transparent #{($base-font-size / 100 * 16) * $line-height + "px"});
}



$light-grey: #f4f4f4;

$image-path: "https://johnmacpherson.com/images/"; 
$image-path: "/assets/images/uploads/images/";


$hero: "newtonmore-2013.jpg";
$hero: "mk2-people-bw-2.jpg";
$hero: "mk2-blue-2.jpg";
/*
@mixin bg-hero {
	background: url(#{$image-path}#{$hero}) 50% 0 ;
	
}
*/


@mixin spritebox($left, $top, $height, $width){
  background-image: url(#{#{$image-path}}/images/sprites.png);
  background-repeat: no-repeat;
  background-position: $left $top;
  height: $height;
  width: $width;
  display: block;
  }
  
  
  
$tablet-width: 50em;
$desktop-width: 75em;

@mixin tablet {
  @media (min-width: #{$tablet-width}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

   
$font1 : "ff-tisa-web-pro",  Tahoma, Arial, Helvetica, sans-serif;
$font2 : "canada-type-gibson", Tahoma, Arial, Helvetica, sans-serif;
$font3 : "ff-tisa-web-pro", "jaf-facitweb", Tahoma, Arial, Helvetica, sans-serif;
$font4 : "futura-pt", sans-serif;

$image-border-radius : 7px;

a:visited			{ color:#7ccbf2}

body				{ font-family: $font1; position: relative; overflow: hidden;
	
&:before		    {background-position: 50% 0; background-size: cover; filter: blur(7px); height: 106%; z-index: -1; position: fixed; top: 0; width: 104%; content: "."; display: block; margin: -2% }
 }
 
img.jm			{ transform: rotate(90deg); transform-origin: 0 100%; width: 100vh; position: fixed; top: 0; left: 0; right: 0; bottom: 0; clip-path: inset(40% 0 0 0);   }
img.jm-right	{ clip-path: inset(0 0 50% 0); left: 97.25%;}	
 
.newtonmore:before		{ background-image: url(#{$image-path}/newtonmore-2013.jpg) }
.mk2-blue-2:before		{ background-image: url(#{$image-path}/mk2-blue-2.jpg) }
.mk2-people-bw-2:before	{ background-image: url(#{$image-path}/mk2-people-bw-2.jpg) }


/*random([' mk2-blue-2', ' mk2-people-bw-2', ' newtonmore']) */

h1,h2,h3            { font-family: $font2;}


.wrapper			{ margin: 1% auto; width: 98%; box-shadow: /*0px 0px 30px rgba(255,255,255,.5),*/ 0px 0px 40px rgba(0,0,0,.9);  border-top-left-radius:$image-border-radius; border-top-right-radius: $image-border-radius;  position: relative; background-color: white;
					@include tablet { margin: 4% auto; width: 90%;}
}

@include tablet { .wrapper:before {content: ""; width: 104%; height: 104%; left: 0; top: 0; background-color: rgba(255,255,255,.035); margin: -2%; position: absolute; z-index: -1; border-radius:$image-border-radius; }
}

.topper a,
form                { pointer-events: auto; }
a img				{ border:none; }


img.home-hero		{ display: block; max-width: 100%; border-top-left-radius:$image-border-radius; border-top-right-radius: $image-border-radius;}


.border-main		{ left:10px; display: block; height: 1px; position: absolute; width: calc(100% - 20px); top: 10px; z-index: 101; background: transparent url(#{$image-path}bg-border-dots-7x7.png) 0 0 repeat-x;}

.border-left        { height: calc(100% - 16px); width: 1px; background-repeat: repeat-y; }
.border-top-left    { width: calc(33.33333% - 10px); background-position: -3px -1px  }
.border-top-right   { left: 33.33333%; width: calc(66.66666% - 10px); background-position: -3px -2px}
.border-right   	{ left:auto; right:13px; height: calc(100% - 16px); width: 1px; background-repeat: repeat-y; background-position: -1px 0 }
.border-bottom      { right: 12px; top: auto;  bottom: 9px; width: auto; background-position: -3px -1px}


header				{ position: absolute; top: 0; left: 0; width: 100%; border-radius: 20px; }

.logo               { width: 45px;  margin: 5%; position: absolute; margin-left: 5%; margin-top: 5%; z-index: 10001; /*filter: drop-shadow(0px 0px 1px white) drop-shadow(0px 0px 10px #000); */
					  @include tablet { width: 60px;}
					  @include desktop { width: 70px;}
}
//.inner .logo			{ filter: drop-shadow(0px 0px 1px #aaa) drop-shadow(0px 0px 10px #ccc) }
.logo  img          { max-width: 100%; }

/*
nav                  { background-color: rgba(0,0,0,0.8); position: absolute; top:0; right: 5px; width: 50%; padding: 4% 0;
					 @include tablet {width:20%; top: 13px; right: 13px;} }
nav li               { display: block; padding: 7px 0; text-align: center; }
nav a				 { text-align: center; text-decoration: none; color: #fff; font-family: $font2; font-size: .7em; text-transform: uppercase;
					 @include tablet {font-size: .9em;}
}
*/


nav {
  top: 0;
  right: 0;
  z-index: 1000000001;
  width: 100%;
  position: absolute;
}
nav .menu {
  top: 0;
  left: 0;
  right: 0;
  margin: 0;
  z-index: 1;
  width: 100%;
  background: rgba(0,0,0,.90);
  padding: 0 2em 0 2em;
  position: absolute;
  transform: translateY(calc(-105%));
  @include tablet {width: 100%; margin: 0 0; transform: translateY(calc(-125%));}
  transition: transform 0.3s cubic-bezier(1, 0.5, 0, 1);
}
nav .menu.active {
	transform: translateY(-10%);
	height: 110vh;
	@include tablet {height: 102vh; }
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: fixed;
	top: auto;
	box-shadow: inset 0 0 100px #000;
	
}
nav .menu li {
  list-style: none;
  text-align: center;
}
nav .menu li:first-child {
  margin-top: -10vh;
}
nav .menu li a {
  padding: 1em;
  color: #fff;
  display: block;
  background: none;
  line-height: .4;
  font-size: 1.55em;
  text-align: center;
  text-decoration: none;
  font-family: $font4;
  display: inline-block;
  position: relative;
  opacity: .05;
  text-transform: uppercase;
  font-weight: 600;
}

nav .menu.active li a {
		transition: 1s .25s ease-out;
		opacity: 1;
}

nav .menu.active li:nth-child(2) a {
		transition-delay: .5s;
}
nav .menu.active li:nth-child(3) a {
		transition-delay: .75s;
}
nav .menu.active li:nth-child(4) a {
		transition-delay: 1s;
}

nav .menu.active li a:hover {
  transition: .25s 0s;
  background-color: #5CB0D4;
}


.menu-toggle {
  top: 9vh;
  z-index: 2;
  right: 5%;
  width: 44px;
  height: 8px;
  padding: 16px 0;
  cursor: pointer;
  position: absolute;
}
.menu-toggle span {
  height: 8px;
  display: block;
  overflow: hidden;
  text-indent: 100%;
  background: #000;
  white-space: nowrap;
  transition: 0.3s ease-in-out;
 // box-shadow: 0px 0px 5px #ccc;
}
.menu-toggle::before, .menu-toggle::after {
  left: -7px;
  content: "";
  width: 36px;
  height: 8px;
  background: #5CB0D4;
  position: absolute;
  transform-origin: center center;
  transition: width 0.2s ease-in-out, background 0.2s ease-in-out, top 0.2s ease-in-out, bottom 0.2s ease-in-out, left 0.2s ease-in-out, right 0.2s ease-in-out;
  transition: width 0.2s ease-in-out, background 0.2s ease-in-out, transform 0.2s ease-in-out, top 0.2s ease-in-out, bottom 0.2s ease-in-out, left 0.2s ease-in-out, right 0.2s ease-in-out;

}
.menu-toggle::before {
  top: 0;
}
.menu-toggle::after {
  bottom: -8px;
}
.menu-toggle.menu-open span {
  background: rgba(255, 255, 255, 0);
  opacity: 0;
}
.menu-toggle.menu-open::before {
  top: 16px;
  transform: rotate(-45deg);
}
.menu-toggle.menu-open::after {
  bottom: 8px;
  transform: rotate(45deg);
}
.menu-toggle.menu-open::before, .menu-toggle.menu-open::after {
  width: 54px;
  background: #5CB0D4;
}
.menu-toggle.menu-open:hover span, .menu-toggle.menu-open:focus span, .menu-toggle.menu-open:active span {
  background: rgba(255, 255, 255, 0);
}
.menu-toggle.menu-open:hover::before, .menu-toggle.menu-open:focus::before, .menu-toggle.menu-open:active::before {
  top: 16px;
  left: -11px;
  transform: rotate(-45deg);
  
}
.menu-toggle.menu-open:hover::after, .menu-toggle.menu-open:focus::after, .menu-toggle.menu-open:active::after {
  left: 9px;
  bottom: 8px;
  transform: rotate(45deg);
}
.menu-toggle.menu-open:hover::before, .menu-toggle.menu-open:hover::after, .menu-toggle.menu-open:focus::before, .menu-toggle.menu-open:focus::after, .menu-toggle.menu-open:active::before, .menu-toggle.menu-open:active::after {
  width: 36px;
}
.menu-toggle:hover span, .menu-toggle:focus span, .menu-toggle:active span {
  background: rgba(255, 255, 255, 0);
  opacity: 0;
}
.menu-toggle:hover::before, .menu-toggle:focus::before, .menu-toggle:active::before {
  top: 10px;
  left: -4px;
  transform: rotate(45deg);
}
.menu-toggle:hover::after, .menu-toggle:focus::after, .menu-toggle:active::after {
  left: 17px;
  bottom: 15px;
  transform: rotate(-45deg);
  //box-shadow: 0px 0px 10px black;
}
.menu-toggle:hover::before, .menu-toggle:hover::after, .menu-toggle:focus::before, .menu-toggle:focus::after, .menu-toggle:active::before, .menu-toggle:active::after {
  width: 36px;
}

.home .menu-toggle span {
  box-shadow: 0px 0px 5px black;
  background-color: white;
}

.home .menu-toggle::before, .home .menu-toggle::after {
  box-shadow: 0px 0px 10px black, 0px 0px 10px black, 0px 0px 10px black, 0px 0px 10px black;
}
.home .menu-toggle:hover span, .home .menu-toggle:focus span, .home .menu-toggle:active span {
  background: rgba(255, 255, 255, 0);
}

/*.inset 			{  font-weight: 600; display: inline-block; color: rgba(0,0,0,.4); text-shadow: 1px 4px 3px #fff,0 0 0 #999; letter-spacing: 1px;}*/
.inset 				{  font-weight: 600; display: inline-block;  letter-spacing: 1px; background-image: linear-gradient(to bottom right, #173480, #2292C4, #A4B83B); -webkit-background-clip: text; background-clip: text; color:transparent;}

.home main				{ margin-top: 7em; text-align: center;}
/*.home h1				{ text-align: center; font-size: 10em; text-transform: uppercase;  font-family: $font2; letter-spacing: -5px;
						  background-image: linear-gradient(to bottom left, rgba(116,156,226,1), rgba(0,15,74,1)); background-clip: text;
						  color: transparent; font-weight: 800;  color: rgba(0,0,0,.1); text-shadow: 1px 1px 15px #fff,0 0 0 #bbb; line-height: 1;
}
*/

.home h1				{ text-align: center; font-size: 10em; text-transform: uppercase;  font-family: $font2;  display: inline-block; line-height: 1;
						  background-image: linear-gradient(to bottom right,#173480, #2292C4 80%, #A4B83B); -webkit-background-clip: text; background-clip: text; color:transparent;
						 }


.home h2				{ text-align: center; font-size: 1.5em; text-transform: uppercase; font-weight: 600; letter-spacing: 3px;  margin: -3.9em 0 4em 0; color:#183314; color: white; text-shadow: 1px 1px 10px black; }

.blog-posts-wrapper			{ margin: 2em 0 3em; padding-bottom: 10em;}
.blog-posts-wrapper	a		{ display: flex; text-decoration: none; line-height: 1.2; text-align: left; padding: 40px 0;}
.blog-posts-wrapper	h3		{ color: #000; display: inline; font-family: $font4; font-weight: 800; text-transform: uppercase; font-size: 1.25em }
.blog-posts-wrapper	p		{ font-size: .9em; color: #333; display: inline;  }
.blog-posts-wrapper	time 	{ width: 10%; display: flex; flex-direction: column; justify-content: center;  text-align: center;}

.blog-posts-wrapper	time *		{ background-color: #967E64;  left:0; top: 0; color: white; font-size: .6em; width:50%; display: block; z-index: 1; text-align: center; box-shadow: inset 0px -5px 2px rgba(0,0,0,0.2); padding: 0 0 5px; margin-bottom: 1px; }
.blog-posts-wrapper	time em		{ }
.blog-posts-wrapper	time strong	{ background-color: #96A061; font-weight: 100; }
.blog-posts-wrapper	time span	{ background-color: #7D9BD6; }

.blog-posts-wrapper	div 		{ width: 90%;}

.blog-posts		{width: 66.66666%; margin: 0 auto; display: block; border-bottom: 1px solid #eee; padding: 1em;}



.journal-entry article		{ width: 90%; margin: 0 auto; padding: 25vh 0;
							  @include tablet { width: 66.66666%; }
							}
.journal-entry h1			{ font-family: $font4; text-transform: uppercase;	/*color:#183314;*/ /*text-shadow: 1px 1px 15px #fff,0 0 0 #888;*/  }
.journal-entry h2			{ font-family: $font4; text-transform: uppercase;	color:#183314; /*text-shadow: 1px 1px 15px #fff,0 0 0 #888;*/  }

article small				{ box-shadow: 0 0 25px #ddd; float: right; padding: 10px 30px; color: #333; font-size: .7rem; margin:1.5em 0 3em;}
article p					{ clear: both;  font-size: .9rem; margin-bottom: 1rem;
							  @include tablet{ font-size: 1rem; @include margin-bottom(1); }}


// [ h1: font-size: [x value from scale], line-height: 3 × 26px, margin-top: 2 × 26px, margin-bottom: 1 × 26px ]
$headings: (
  h1: (3, 3, 0, 1),
  h2: (1, 1.5, 1.5, .5),
  h3: (0, 1, 2, 0),
  h4: (0, 1, 1, 0),
  h5: (0, 1, 1, 0),
  h6: (0, 1, 1, 0)
);
@include tablet { 
@each $heading, $properties in $headings {
   		.journal-entry #{$heading} {
   		font-size: ms(nth($properties, 1));
   		@include line-height(nth($properties, 2));
   		@include margin-top(nth($properties, 3));
   		@include margin-bottom(nth($properties, 4));
		}
   }
}




      
form input                  { box-shadow: 0px 0px 5px #ccc, inset 0 0 25px #999; border: 1px solid white; }
form input[type=submit]     { box-shadow: none; border: 1px solid black; }


footer          { clear: both; width: 100%;}
footer h3          { text-align: center; margin: 40px 0 10px; font-weight: 100; font-size: 2em; font-family: $font1}

.footer-inner   { overflow: hidden; padding: 40px 0 ;}


.facebook span  { @include spritebox(-212px, -0px, 76px, 36px); left: 50%; margin-left: -18px; position: absolute; margin-top: -35px; top: 50%; }

.footer-form-holder { margin-left: 15%; top: 50%; position: relative;}
footer label     { position: absolute; top: 0; left: 18px; z-index: 100001;}

footer input     {  width: 100%; margin-top: -20px; position: relative; padding: 20px; float: left;}

footer input[type=submit]     { float: left; width: 100%; margin: 9px 0 20px 19px; background-color: #212121; border: 0;  padding: 10px 20px; color: white; clear: both;}







  

@media screen and (min-width: 800px) {
    .home-wrapper       { flex-flow: row nowrap;  }


}

.outer-wrapper         {}

.flex-container  { display: -ms-flexbox; -ms-flex-direction: column; -ms-flex-wrap: wrap; display: flex; flex-direction: column; flex-wrap: wrap;
                   height: 100vw; font-size: 0; }
.flex-item {
  flex: 0 0 auto; /* shorthand for flex-grow flex-shrink flex-basis */	
	-webkit-perspective: 1000px;
	-moz-perspective: 1000px;
	perspective: 1000px;
	list-style: none;
	 width: 50%; /* 2 col */
}
.flex-item:hover {
	z-index: 1000000101111000;
	position: relative;
	}
@media screen and (min-width: 600px) {
  .flex-container {
    flex-flow: column wrap; /* trigger columnns */
  }
  .flex-item {
   
  }
}
@media screen and (min-width: 1000px) {
  .flex-item {
    width: 33.333%; /* 3 col */
  }
  .photos .image-container:hover {
	-webkit-transform: rotateX(78deg) translateZ(-20px) translate3d( 0, 0, 0);
	-moz-transform: rotateX(78deg) translateZ(-20px);
	transform: rotateX(78deg) translateZ(-20px);
    }
} 

.photos .image-container {
	width: 100%;
	height: auto;
	display: block;
	-webkit-transform-style: preserve-3d;
	-moz-transform-style: preserve-3d;
	transform-style: preserve-3d;

	-webkit-transition: -webkit-transform 350ms;
	-moz-transition: -moz-transform 350ms;
	transition: transform 350ms;
	transform-origin: 0% 0%;
	text-decoration: none;
}


.image  { max-width: 100%; display: block;}

.info   { color:white; padding: 0; width: 100%; transform-origin: 0% 0%; border-left: 1px solid black; border-right: 1px solid black; 
                        	-webkit-transform: rotateX(270deg) ;
	                        -moz-transform: rotateX(270deg) ;
                            transform: rotateX(270deg); backface-visibility: hidden; }

.info .content { position: absolute; top: 0; left:0; font-size: 15px; height: 120px; padding: 5px 20px; 
    
    
    
background: #009fe3; /* Old browsers */
background: -moz-linear-gradient(-45deg,  #009fe3 0%, #0d88e5 100%); /* FF3.6+ */
background: -webkit-gradient(linear, left top, right bottom, color-stop(0%,#009fe3), color-stop(100%,#0d88e5)); /* Chrome,Safari4+ */
background: -webkit-linear-gradient(-45deg,  #009fe3 0%,#0d88e5 100%); /* Chrome10+,Safari5.1+ */
background: -o-linear-gradient(-45deg,  #009fe3 0%,#0d88e5 100%); /* Opera 11.10+ */
background: -ms-linear-gradient(-45deg,  #009fe3 0%,#0d88e5 100%); /* IE10+ */
background: linear-gradient(135deg,  #009fe3 0%,#0d88e5 100%); /* W3C */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#009fe3', endColorstr='#0d88e5',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */

    
    
    
    
    
     } 
.info .content p {  font-size: 12px;}

.info .content h3 { display: block; border-bottom: 1px dotted #666; padding:0 0 5 px 0; margin-bottom: 5px;}
.info .content h4 { border-top: 1px dotted #666; padding:5px 0 5px 0; margin:10px 0 5px; text-align: center;}


/*************************************
Fallback
**************************************/
.no-csstransforms3d .image-container .image, 
.no-csstransforms3d .image-container .info {
	position: relative;
}



